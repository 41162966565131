import React, { useEffect, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout } from '../components'

import { container, padding, colours, type, bgImage, pagePaddingTop, wrapper } from '../styles/global'
import { media } from '../styles/utils'
import { parseACF } from '../utils'
import { subscribeContext } from '../components/Subscribe/SubscribeProvider'
import { useMount, useUnmount } from 'react-use'

const Connect = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)
    const subscribe = useContext(subscribeContext)

	useMount(() => {
        subscribe.setActive(true)
        subscribe.setDisableClose(true)
    })

    useUnmount(() => {
        subscribe.setActive(false)
        subscribe.setDisableClose(false)
    })

	return (
		<Layout
            meta={data.seo}
            hideFooter
            subscribeZindex={9}
        >
			<Wrapper>

			</Wrapper>
		</Layout>	
	)
}

const Wrapper = styled.div`
	${wrapper}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "subscribe" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`


export default Connect